<template>
    <div class="about_us_container">
        <Header></Header>
        <div class="container">
            <div class="title_container">
                <!-- <div class="title_box">
                    <h2 class="title">关于我们</h2>
                    <h3 class="sub_title">About Us</h3>
                </div> -->
            </div>
            <div class="about_us_introduce">
                <h3 class="content_title">跑跑网简介</h3>
                <p class="content_text">
                    杭州跑跑网络科技有限公司成立于2015年3月，是从事体育产业 +移动互联网高新科技研发中心及运动信息采集实验室。跑跑位于美丽的杭州钱塘江畔。
                </p>
                <p class="content_text">
                    跑跑网是AIMS国际马拉松及公路跑协会官方合作伙伴，官方指定跑步APP。成立以来专注于服务广大跑者，研发及运营APP“马拉松报名” 及微信公众号“马拉松报名”，为广大跑友提供更新的赛事新闻及报名服务，服务了数百万计的跑友，为跑友提供一站式的从报名到酒店住宿再到目的地出行马拉松旅行服务，公司也致力于合作更多著名的国外赛事，让更多中国跑友跑出去，外国跑友跑进来。 
                </p>
                <p class="content_text">
                    跑跑网马拉松报名为各大马拉松组委会提供一整套的专业跑友报名系统APP、微信公众号、PC网站，高效、便捷、稳定；为各大马拉松提供全套信息采集服务。
                </p>
                <p class="content_text">
                    跑跑网立足马拉松，服务于跑者，为用户提供优质服务，为客户创造商业价值，赢得市场的同时，为全球运动健康产业的发展作贡献，为人类生命运动科学作贡献！
                </p>
                <h3 class="content_title contact_us">联系我们：</h3>
                <p class="content_text">公司：杭州跑跑网络科技有限公司</p>
                <p class="content_text">地址：浙江省杭州市滨江区闻涛路西兴路中海寰宇商务中心A座904</p>
                <p class="content_text">电话：0571-87758152</p>
                <p class="content_text">邮箱：bd@hpaopao.com</p>
                <p class="content_text">官方微信公众平台：马拉松报名、跑跑网</p>
                <p class="content_text">官方微博：跑跑网</p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    name: 'aboutUs'
}
</script>
<style scoped>
    @import './style/aboutUs.css';
</style>